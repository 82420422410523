import React, { useState, useEffect } from 'react';
import { getApiRequest } from '../../../utils/request';

function Select({ formik, name, id, hasError, options, optionsUrl }) {
  const [localOptions, setLocalOptions] = useState([{ value: '', label: 'Loading...' }]);
  useEffect(() => {
    if (options) {
      setLocalOptions(options);
    } else if (optionsUrl) {
      const remoteLoadOptions = async () => {
        try {
          const results = await getApiRequest(optionsUrl);
          if (results.options) {
            setLocalOptions([
              {
                value: '',
                label: 'Select...',
              },
              ...results.options,
            ]);
          }
        } catch (err) {
          console.log(err, 'error fetching options');
        }
      }
      remoteLoadOptions();
    }
  }, [optionsUrl, options, setLocalOptions]);

  const classes = hasError ? 'border-red-300 focus:ring-red-500 focus:border-red-500' : 'border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500';
  const ariaProps = hasError ? { 'aria-invalid': 'true', 'aria-describedby' : `${id}-error` } : { 'aria-describedby' : `${id}-description` };
  return (
    <select
      {...formik.getFieldProps(name)}
      {...ariaProps}
      className={`mt-1 block w-full pl-3 pr-10 py-2 text-base sm:text-sm rounded-md ${classes}`}
      id={id}
      name={name}
    >
      {localOptions.map((option) => (<option key={`${option.value}_${option.label}`} value={option.value}>{option.label}</option>))}
    </select>
  );
}

export default Select;
