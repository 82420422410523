import React from 'react';
import Label from './label';
import HelpText from './help-text';
import Input from './types/input';
import Select from './types/select';
import Phone from './types/phone';
import Checkbox from './types/checkbox';
import Checkboxes from './types/checkboxes';
import File from './types/file';
import Textarea from './types/textarea';
import DateInput from './types/date';
import TimeInput from './types/time';
import SelectLookup from './types/select-lookup';
import Markdown from './types/markdown';


function FieldSwitch({ formik, field, hasError, id }) {
  const { type } = field;
  switch (type) {
    case 'phone':
      return <Phone formik={formik} {...field} hasError={hasError} id={id} />;
    case 'select':
      return <Select formik={formik} {...field} hasError={hasError} id={id} />;
    case 'select-lookup':
      return <SelectLookup formik={formik} {...field} hasError={hasError} id={id} />;
    case 'checkbox':
      return <Checkbox formik={formik} {...field} hasError={hasError} id={id} />;
    case 'checkboxes':
      return <Checkboxes formik={formik} {...field} hasError={hasError} id={id} />;
    case 'file':
      return <File formik={formik} {...field} hasError={hasError} id={id} />;
    case 'textarea':
      return <Textarea formik={formik} {...field} hasError={hasError} id={id} />;
    case 'markdown':
      return <Markdown formik={formik} {...field} hasError={hasError} id={id} />;
    case 'date':
      return <DateInput formik={formik} {...field} hasError={hasError} id={id} />;
    case 'time':
      return <TimeInput formik={formik} {...field} hasError={hasError} id={id} />;
    case 'text':
    case 'email':
    case 'number':
    case 'password':
    default:
      return <Input formik={formik} {...field} hasError={hasError} id={id} />;
  }
}

function Field({ formik, field }) {
  const { id, name, label, hideLabel, helpText, showIf } = field;
  const realId = id || name;
  const hasError = formik.touched[name] && formik.errors[name];
  const errorText = hasError ? formik.errors[name] : null;

  // Skip fields that are not set to show, if we have a showIf function
  if (showIf && !showIf(formik)) {
    return null;
  }
  return (
    <div>
      <Label id={realId} label={label} hidden={hideLabel} />
      <FieldSwitch formik={formik} field={field} hasError={hasError} id={realId} />
      <HelpText id={realId} helpText={helpText} hasError={hasError} errorText={errorText} />
    </div>
  );
}

export default Field;
